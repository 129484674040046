export const isProd = process.env.NODE_ENV === 'production'

// const DEFAULT_HOST = 'staging.reflowhub.com'
const DEFAULT_HOST = isProd ? 'reflowhub-server.zipdrop.xyz' : 'localhost:8889'
// const DEFAULT_HOST = 'reflowhub-server.cloud1.zipdrop.xyz/'

/**
 * Checks we're currently in the context of a browser (other gatsby build fails).
 */
export const isBrowser = typeof window !== 'undefined'

const TENANT_REGEX = /^([^\\.]+).(reflowhub.com|localhost|kingfisher-mx.com|kingfishernexus.com)$/i

export const getTenantFromDomainName = () => {
  if (isBrowser) {
    const match = window.location.hostname.match(TENANT_REGEX)
    if (match) {
      return match[1]
    }
  }
  return null
}

/**
 * Returns the backend hostname to use.
 */
export const getHost = () => {
  const tenant = getTenantFromDomainName()
  return (isProd && tenant !== null) ? `${tenant}-api.reflowhub.com` : DEFAULT_HOST
}

export const getApiUrl = () => {
  const host = getHost()
  return process.env.GATSBY_API_BASEURL || (host.startsWith('localhost') ? `http://${host}` : `https://${host}`)
}

export const getWssUrl = () => {
  const host = getHost()
  return process.env.GATSBY_WSS_BASEURL || (host.startsWith('localhost') ? `ws://${host}` : `wss://${host}`)
}
